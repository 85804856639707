import React from "react"
import SEO from "components/seo"
import { FormContainer } from "components/Form"

const Contact = () => {
  return (
    <>
      <SEO
        title={"Kontakt"}
        description={
          "Fundacja Świat Bez Barier. ul. Księdza Piotra Skargi 1, 37-700 Przemyśl woj. Podkarpackie, telefon +48 693 380 754"
        }
      />
      <FormContainer />
    </>
  )
}

export default Contact
